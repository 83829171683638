import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: function () {
      return import('../views/LoginView.vue')
    }
  },
  {
    path: '/poliza',
    name: 'poliza',
    component: function () {
      return import('../views/PolizaView.vue')
    }
  },
  {
    path: '/bienvenido',
    name: 'bienvenido',
    component: function () {
      return import('../views/BienvenidoView.vue')
    }
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: function () {
      return import('../views/CheckoutView.vue')
    }
  },
  {
    path: '/pago-previo',
    name: 'pago-previo',
    component: function () {
      return import('../views/PagoPrevioView.vue')
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: function () {
      return import('../views/DashboardView.vue')
    }
  },
  {
    path: '/mis-servicios',
    name: 'servicios',
    component: function () {
      return import('../views/ServicioView.vue')
    }
  },
  {
    path: '/incidencias',
    name: 'incidencias',
    component: function () {
      return import('../views/IncidenciaView.vue')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
