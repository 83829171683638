<script setup>
import { ref, inject, computed } from 'vue'
import ItemDivider from '@/components/general/ItemDivider.vue'
import { useDisplay } from 'vuetify'

const { lgAndUp } = useDisplay()
const isLgUp = computed(()=> lgAndUp.value)
const isOpen = inject('openMenu');

const menuUser = ref([
    { title: 'Perfil', icon: "mdi-account", "url": "" },
    { title: 'Cerrar Sesion', icon: "mdi-logout", "url": "" },
],)

const listMenu = ref([
    { title: 'Dashboard', icon: "mdi-view-dashboard", "url": "/dashboard" },
    { title: 'Mis servicios', icon: "mdi-view-list-outline", "url": "/mis-servicios" },
    { title: 'Incidencias', icon: "mdi-flag-outline", "url": "/incidencias" },
],)
</script>

<template>
    <v-navigation-drawer class="bg-white elevation-1 py-4 px-2" temporary :permanent="isLgUp" v-model="isOpen">
        <v-list>
            <v-list-item class="d-none d-lg-block">
                <v-list-img>
                    <v-img class="mx-auto" lazy-src="https://picsum.photos/id/11/100/60" max-width="180"
                        :src="require(`@/assets/logo.svg`)" />
                </v-list-img>
            </v-list-item>
            <v-list-item :prepend-avatar="require(`@/assets/avatar.jpg`)" title="Maximiliano Magaña">
                <template v-slot:append>
                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-btn size="small" v-bind="props" variant="text" icon="mdi-dots-horizontal"></v-btn>
                        </template>
                        <v-list>
                            <v-list-item :prepend-icon="itemUser.icon" :to="itemUser.url" v-for="(itemUser, i) in menuUser" :key="i">
                                <v-list-item-title>{{ itemUser.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-list-item>
        </v-list>
        <item-divider class="my-6" />
        <v-list color="transparent">
            <v-list-item color="primary" link v-for="(itemMenu, i) in listMenu" :key="i" 
            :to="itemMenu.url"
            :prepend-icon="itemMenu.icon" :title="itemMenu.title"></v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>