import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import '@/main.scss'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
// import colors from 'vuetify/lib/util/colors'


const myCustomLightTheme = {
    dark: false,
    colors: {
        primary: '#2a59c3',
        secondary: '#071f48',
        error: '#f20000',
        info: '#f6f8fc',
        accent: "#f46a5a",
        success: '#299f3c',
        warning: '#FB8C00',
        base100: '#c3edc6',
        base200: "#737373",
        base300: "#dcdfe3",
        base400: "#545f71",
        base500: "#9b9fa7"
    },
}

const vuetify = createVuetify({
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
            myCustomLightTheme,
        },
    },
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
    },
})

export default vuetify;