<script setup>
import { defineEmits } from 'vue'

const emit = defineEmits(['toggle-menu']);

const handleToggleMenu = () => {
  emit('toggle-menu');
};
</script>
<template>
    <v-app-bar class="px-8">
        <v-img :lazy-src="require(`@/assets/logo.svg`)" max-width="150" :src="require(`@/assets/logo.svg`)" />
        <v-spacer></v-spacer>
        <v-btn icon class="bg-primary ma-0" @click="handleToggleMenu">
          <v-icon color="white">mdi-menu</v-icon>
        </v-btn>
    </v-app-bar>
</template>