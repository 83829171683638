<script setup>
import MenuSide from '@/components/menu/MenuSide.vue'
import AppBar from '@/components/menu/AppBar.vue'
import { ref, computed, provide } from 'vue'
import { useDisplay } from 'vuetify'

const { lgAndUp } = useDisplay()
const isLgUp = computed(()=> lgAndUp.value)

const openMenu = ref(false);

if(lgAndUp){
    openMenu.value = true;
}

provide('openMenu', openMenu)

const handleMenu = () =>{
    openMenu.value = !openMenu.value;
};

</script>

<template>
    <app-bar @toggle-menu="handleMenu" v-if="!isLgUp" />
    <menu-side v-model:open-menu="openMenu" />
</template>